

































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'SupportSpeakersVideos'
})

export default class extends Vue {
  // Video content for all partners and brokers
  private videos = [
    {
      title: '1. Εισαγωγή - Χαιρετισμός Κυριάκου Κλαδάκη & Κωνσταντίνου Τζάρου, συνιδρυτών Bee real estate',
      description: '',
      url: 'https://www.youtube.com/watch?v=SwT41yO0a3Y'
    },
    {
      title: '2. Εισηγητής: Αριστείδης Απ. Παπώτης',
      description: 'Αγρονόμος - Τοπογράφος Μηχανικός (Α.Π.Θ.) Πιστοποιημένος Εκτιμητής Ακινήτων (REV)\nΘέμα: Η σύγχρονη πολεοδομική νομοθεσία και η εξέλιξη της σχετικής νομολογίας για την εκτός σχεδίου δόμηση.',
      url: 'https://www.youtube.com/watch?v=AvXmKPYaovQ'
    },
    {
      title: '3. Εισηγήτρια: Ανθή Παπαδοπούλου',
      description: 'Δικηγόρος, MSc Εμπορικού Δικαίου, LLM Δίκαιο & Πληροφορική\nΘέμα: Η δέουσα επιμέλεια ως διαδικασία ελέγχου. Η ευθύνη του μεσίτη.',
      url: 'https://www.youtube.com/watch?v=_hAqS0-JhvI'
    },
    {
      title: '4. Εισηγητής: Μάριος Βογιατζής',
      description: 'Αναλυτής Συμπεριφοράς / Behavioral Analyst\nΙδρυτής Ινστιτούτου Γλώσσας Σώματος\nΘέμα: Master your silent language - Αξιολόγηση πελατών\nΜέρος Α\': Διερεύνηση συμπεριφοράς πελατολογίου - Biasses - Mindset\nΜέρος Β\': Κανάλια επικοινωνίας - λεκτικά και μη - σημάδια επικοινωνίας',
      url: 'https://www.youtube.com/watch?v=gafk622a9gg'
    },
    {
      title: '5. Εισηγητές: Ηλίας Παππάς / Founder OWNERS - Κώστας Παππάς / Co founder OWNERS',
      description: 'Θέμα: OWNERS / Second Home Co-Ownership\nΈνα νέο προϊόν για τον Έλληνα Μεσίτη.',
      url: 'https://www.youtube.com/watch?v=cl8Z9FMACiE'
    },
    {
      title: '6. Βραβεύσεις συνεργατών - γραφείων Bee real estate.',
      description: '',
      url: 'https://www.youtube.com/watch?v=ODsNMy-2cQ0'
    }
  ]

  // Convert regular YouTube URLs to embed URLs
  private getEmbedUrl(url: string): string {
    const videoId = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)[2].split(/[^0-9a-z_\-]/i)[0]
    return `https://www.youtube-nocookie.com/embed/${videoId}`
  }
}
